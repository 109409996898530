const submenuDashboard = {
  title: "Dashboard",
  route: "dashboard",
  icon: "HomeIcon",
  isNavMenuHidden: false,
  auth: ['corporate', 'superadmin']
}

const submenuBanner = {
  title: "Manage Banner",
  icon: "ImageIcon",
  route: "banner",
  isNavMenuHidden: false,
  auth: ['corporate', 'superadmin']
}

const submenuSurvey = {
  title: "Manage Questioner",
  icon: "DatabaseIcon",
  route: "questioner",
  isNavMenuHidden: false,
  auth: ['corporate', 'superadmin']
}

const submenuUser = [
  {
    title: 'User Corporate',
    route: 'user-corporate',
    isNavMenuHidden: false,
    auth: ['superadmin']
  },
  {
    title: 'User Member',
    route: 'user-member',
    isNavMenuHidden: false,
    auth: ['corporate', 'superadmin']
  }
]

const submenuLeaderboard = [
  {
    title: 'Top Transaction',
    route: 'leader-top-transaction',
    isNavMenuHidden: false,
    auth: ['superadmin']
  },
  {
    title: 'Top Reseller',
    route: 'leader-top-reseller',
    isNavMenuHidden: false,
    auth: ['superadmin']
  }
]

const submenuPartner = [
  {
    title: 'Master Partner',
    route: 'partner',
    isNavMenuHidden: false,
    auth: ['corporate', 'superadmin']
  },
  {
    title: 'Partner Tracking',
    route: 'partner-tracking',
    isNavMenuHidden: false,
    auth: ['corporate', 'superadmin']
  }
]

const submenuEvent = [
  {
    title: "Event Data",
    route: "event",
    isNavMenuHidden: false,
    auth: ['corporate', 'superadmin']
  },
  {
    title: "Log Event",
    route: "log-event",
    isNavMenuHidden: false,
    auth: ['corporate', 'superadmin']
  },
  {
    title: "Log Member",
    route: "log-member",
    isNavMenuHidden: false,
    auth: ['corporate', 'superadmin']
  }
]

const submenuPolling = {
  title: "Manage Polling",
  icon: "DatabaseIcon",
  route: "polling",
  isNavMenuHidden: false,
  auth: ['corporate', 'superadmin']
}

export default [
  submenuDashboard,
  {
    title: "Manage User",
    icon: "UsersIcon",
    children: submenuUser,
    auth: ['corporate', 'superadmin']
  },
  {
    title: "Leaderboard",
    icon: "AwardIcon",
    children: submenuLeaderboard,
    auth: ['corporate', 'superadmin']
  },
  {
    title: "Partner",
    icon: "UserCheckIcon",
    children: submenuPartner,
    auth: ['corporate', 'superadmin']
  },
  submenuBanner,
  {
    title: "Manage Event",
    icon: "CalendarIcon",
    children: submenuEvent,
    auth: ['corporate', 'superadmin']
  },
  submenuSurvey,
  submenuPolling
];
