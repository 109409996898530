<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ name }}
            </p>
            <span class="user-status">{{ role }}</span>
          </div>
          <b-avatar
            :src="`https://api.the-netwerk.com/public/storage/member/profile/${logo}`"
          />
        </template>

        <b-dropdown-item link-class="d-flex align-items-center" @click="goToProfile()">
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center" @click="goToChat()">
          <feather-icon
            size="16"
            icon="MailIcon"
            class="mr-50"
          />
          <span>Inbox</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center" @click="goToChat()">
          <feather-icon
            size="16"
            icon="MessageSquareIcon"
            class="mr-50"
          />
          <span>Chat</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item 
          link-class="d-flex align-items-center"
          @click="logout">
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    name() {
      return localStorage.getItem("name")
    },
    role() {
      let role = localStorage.getItem("role")
      role = role.charAt(0).toUpperCase() + role.slice(1);
      
      return role
    },
    logo() {
      return localStorage.getItem("logo")
    }
  },
  methods: {
    logout() {
      localStorage.removeItem("role")
      localStorage.removeItem("name")
      localStorage.removeItem("logo")
      localStorage.removeItem("token")

      this.$router.go({ name: 'login' })
    },
    goToProfile() {
      this.$router.push({ name: 'profile' })
    },
    goToChat() {
      location.href = "https://api.whatsapp.com/send?phone=6285100441777"
    },
    goToInbox() {

    }
  }
}
</script>
